<template>
    <div id="app">

        <div v-if="qrscan.type !== false" style="margin-bottom: 10px">

            <h1 v-if="qrscan.type=='t'" :style="'color: ' + (this.qrscan.valid ? 'green' : 'red')">Covid Test Certificate</h1>
            <h1 v-else-if="qrscan.type=='r'" :style="'color: ' + (this.qrscan.valid ? 'green' : 'red')">Covid Recovery Certificate</h1>
            <h1 v-else-if="qrscan.type=='v'" :style="'color: ' + (this.qrscan.valid ? 'green' : 'red')">Covid Vaccination Certificate</h1>
            <h1 v-else>Unknown Certificate (type {{qrscan.type}})</h1>
            <h2 v-if="this.error" :style="'color: '+this.error.color">{{error.text}}</h2>
            <h2 v-else style="color: green">certificate correctly signed</h2>
            <h2 v-if="!this.qrscan.valid" style="color: red">{{this.qrscan.validDesc}}</h2>
            <table>
                <tr><th>Name</th><td>{{qrscan.name}}</td></tr>
                <tr><th>Birthdate</th><td>{{qrscan.birthdate}}</td></tr>
                <tr><th>Certificate Issuer</th><td>{{qrscan.cert.issuer}} ({{qrscan.cert.country}})</td></tr>
                <tr><th>Certificate Identifier</th><td>{{qrscan.cert.identifier}}</td></tr>
                <tr v-if="qrscan.type=='t'"><th>Testcenter</th><td>{{qrscan.cert.company}}</td></tr>
                <tr v-if="qrscan.type!='r'"><th>Product</th><td>{{qrscan.cert.product}}<span v-if="qrscan.cert.productDesc && qrscan.cert.productDesc!==''"> ({{qrscan.cert.productDesc}})</span></td></tr>
                <tr><th>Date</th><td>{{qrscan.cert.datetime}}</td></tr>
                <tr v-if="qrscan.type=='v'"><th>Vaccinations</th><td>{{qrscan.cert.stage}} / {{qrscan.cert.stages}}</td></tr>
                <tr><th>Cert. version</th><td>{{qrscan.version}}</td></tr>
            </table>
        </div>
        <h1 v-else>Scan QR-Code of european vaccination or test certificate</h1>

        <qrcode-drop-zone @decode="onDecode"
                          @init="onInit">
            <qrcode-stream @decode="onDecode"
                           @init="onInit"
                           size="small"></qrcode-stream>
        </qrcode-drop-zone>

    </div>
</template>

<script>

const base45 = require('base45-js');
const pako = require('pako');
const cose = require('cose-js');
const cbor = require('cbor');
const moment = require('moment');
const zlib = require('zlib');
import tests from './tests.json';
import publicKeys from './keys.json';
import { Certificate } from "pkijs";
import * as asn1js from "asn1js";
import { PublicKey } from '@fidm/x509';

export default {
    name: 'App',
    data() {
        return {
            error: false,
            qrscan: {
                cert: {},
                birthdate: '',
                name: '',
                type: false,
                version: ''
            },
            trustList: {}
        }
    },
    methods: {
        onDecode(result) {
            this.error = false;
            let kid;
            let siKid;
            this.qrscan = {
                cert: {},
                birthdate: '',
                name: '',
                type: false,
                version: '?',
                raw: []
            };
            if (result && result !== '') {
                if (result.startsWith("HC1:")) {
                    // console.log('raw',result);
                    const b45decoded = base45.decode(result.slice(4));
                    // console.log('b45decoded',b45decoded);
                    const decompressed = pako.inflate(b45decoded);
                    // console.log('decompressed',decompressed);
                    const decoded = cbor.decode(decompressed);
                    // console.log('decoded',decoded);

                    // const signatureInfo = cbor.decode(decoded.value[0]);
                    // console.log('signatureInfo',signatureInfo);

                    const pHeader = cbor.decode(decoded.value[0]);
                    const uHeader = decoded.value[1];
                    const payload = cbor.decode(decoded.value[2]);
                    const kid = pHeader.get(cose.common.HeaderParameters.kid) || uHeader.get(cose.common.HeaderParameters.kid);
                    if (kid) {
                        const kidBase64 = Buffer.from(kid).toString("base64");
                        let valid = false;
                        if (kidBase64 in publicKeys) {
                            const key = publicKeys[kidBase64][0].subjectPk;
                            const pk = PublicKey.fromPEM('-----BEGIN PUBLIC KEY-----\n' + key + '\n-----END PUBLIC KEY-----');
                            const keyX = Buffer.from(pk.keyRaw.slice(1, 1 + 32));
                            const keyY = Buffer.from(pk.keyRaw.slice(33, 33 + 32));
                            const verifier = { 'key': { 'x': keyX, 'y': keyY,  'kid': kid } };
                            cose.sign.verify(decompressed, verifier).then(res => {
                                console.info('signature verfied')
                            }).catch(e => {
                                this.error = {text: 'SIGNATURE OF CERTIFICATE INVALID', color: 'red' };
                            })

                        } else if (kidBase64 in this.trustList) {
                            const tl = this.trustList[kidBase64];
                            if (tl.hasOwnProperty('x') && tl.hasOwnProperty('y') && tl.hasOwnProperty('x5c')) {
                                let x = tl.x;
                                let y = tl.y;
                                let x5c = tl.x5c[0];
                                const certificateRaw = Buffer.from(x5c, "base64");
                                const asn1 = asn1js.fromBER(certificateRaw.buffer);
                                const certificate = new Certificate({schema: asn1.result});
                                const signer = {
                                    key: {
                                        x: Buffer.from(x, "base64"),
                                        y: Buffer.from(y, "base64"),
                                        kid: Buffer.from(kid, "base64"),
                                    },
                                    certificate: certificate,
                                };
                                cose.sign.verify(decompressed, signer).then(res => {
                                    console.info('signature verfied')
                                }).catch(e => {
                                    this.error = {text: 'SIGNATURE OF CERTIFICATE INVALID', color: 'red'};
                                });
                            } else {
                                this.error =  {text: 'NOT SIGNED BY TRUSTED VERTIFICATE ISSUER', color: 'red' };
                            }
                        } else {
                            this.error =  {text: 'NOT SIGNED BY TRUSTED VERTIFICATE ISSUER', color: 'red' };
                        }
                    } else {
                        this.error =  {text: 'Cannot verify certificate signature (cert version < 1.3.0 ?), just show contents', color: 'black' };
                    }

                    // extract payload

                    const decodedCert = cbor.decode(decoded.value[2]);
                    // console.log('decodedCert',decodedCert);
                    const hcert = decodedCert.get(-260).get(1);
                    // console.log(hcert);
                    this.qrscan.birthdate = moment(hcert.dob).format('DD.MM.YYYY');
                    this.qrscan.name = hcert.nam.gn + ' ' + hcert.nam.fn;
                    this.qrscan.version = hcert.ver;
                    if (hcert.hasOwnProperty('t')) {
                        const cert = hcert.t[0];
                        this.qrscan.type = 't';
                        this.qrscan.cert = {
                            identifier: cert.ci,
                            country: cert.co,
                            issuer: cert.is,
                            datetime: moment(cert.sc).format('DD.MM.YYYY HH:mm'),
                            company: cert.tc,
                            product: cert.tt,
                            productDesc: ''
                        }
                        let a = moment();
                        let b = moment(cert.sc);
                        let diff = a.diff(b, 'hours');
                        if (diff > 48) {
                            this.qrscan.valid = false;
                            this.qrscan.validDesc = 'test is ' + diff + ' hours old.';
                        } else {
                            this.qrscan.valid = true;
                        }
                        let tmp = tests.entries.find(t => t.type_code == cert.tt);
                        if (tmp) {
                            this.qrscan.cert.productDesc = tmp.name;
                        }
                    }
                    if (hcert.hasOwnProperty('v')) {
                        const cert = hcert.v[0];
                        this.qrscan.type = 'v';
                        this.qrscan.cert = {
                            identifier: cert.ci,
                            country: cert.co,
                            issuer: cert.is,
                            datetime: moment(cert.dt).format('DD.MM.YYYY'),
                            stage: cert.dn,
                            stages: cert.sd,
                            product: cert.mp ?? '',
                            productDesc: ''
                        };
                        let a = moment();
                        let b = moment(cert.dt);
                        let diff = a.diff(b, 'days');
                        if (diff < 14) {
                            this.qrscan.valid = false;
                            this.qrscan.validDesc = 'vaccination will be valid in ' + (14 - diff) + ' days.';
                        } else if (diff > 180) {
                            this.qrscan.valid = false;
                            this.qrscan.validDesc = 'vaccination should be refreshed, ' + diff + ' days since vaccination.'
                        } else {
                            this.qrscan.valid = true;
                        }
                        diff = parseInt(cert.sd) - parseInt(cert.dn);
                        if (diff > 0) {
                            this.qrscan.valid = false;
                            this.qrscan.validDesc = diff + ' more vaccination(s) needed';
                        }

                        if (this.qrscan.cert.product == 'EU/1/20/1528') {
                            this.qrscan.cert.productDesc = 'Biontec Comirnaty';
                        }
                        if (this.qrscan.cert.product == 'EU/1/20/1507') {
                            this.qrscan.cert.productDesc = 'Moderna';
                        }
                        if (this.qrscan.cert.product == 'EU/1/21/1529') {
                            this.qrscan.cert.productDesc = 'Vaxzevria';
                        }
                        if (this.qrscan.cert.product == 'EU/1/20/1525') {
                            this.qrscan.cert.productDesc = 'Janssen';
                        }
                    }
                    if (hcert.hasOwnProperty('r')) {
                        const cert = hcert.r[0];
                        this.qrscan.type = 'r';
                        this.qrscan.cert = {
                            identifier: cert.ci,
                            country: cert.co,
                            issuer: cert.is,
                            datetime: moment(cert.dt).format('DD.MM.YYYY')
                        }
                        let a = moment();
                        let b = moment(cert.dt);
                        let diff = a.diff(b, 'days');
                        if (diff > 180) {
                            this.qrscan.valid = false;
                            this.qrscan.validDesc = 'vaccination should be refreshed, ' + diff + ' days since recovery.'
                        } else {
                            this.qrscan.valid = true;
                        }
                    }

                } else {
                    this.error = { text: 'NO COVID TEST- OR VACCINATION CERTIFICATE', color: 'orange'}
                }
            }
        },
        getTrustList() {
            fetch('https://eudcc.tibordp.workers.dev/trust-list/test').then(response => response.json()).then(data => {
                this.trustList = data;
            })
        },
        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                let msg = 'ERROR';
                if (error.name === 'NotAllowedError') {
                    msg = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    msg = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    msg = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    msg = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    msg = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    msg = "ERROR: Stream API is not supported in this browser"
                }
            }
        }
     },
    mounted() {
        this.getTrustList();
    }
}
</script>

<style>
table {
    border-collapse: collapse;
}
td,th {
    padding: 4px;
    border: 1px solid black;
}
th {
    align: left;
}

</style>

import Vue from 'vue'
import App from './App.vue'
import VueQrcodeReader from "vue-qrcode-reader";
import VueQRCodeComponent from 'vue-qrcode-component'

Vue.config.productionTip = false

Vue.use(VueQrcodeReader);
Vue.component('qr-code', VueQRCodeComponent)

new Vue({
  render: h => h(App),
}).$mount('#app')
